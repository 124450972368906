import React, { FC, useState, Suspense } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'

import Login from './pages/Login'
import Consent from './pages/Consent'
import Project from './pages/Project'
import MyProjects from './pages/MyProjects'
import NotFound from './pages/NotFound'
import NoAccess from './pages/NoAccess'

// Code split admin functions
const About = React.lazy(() => import('./pages/About'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const CookiesPolicy = React.lazy(() => import('./pages/CookiesPolicy'))
const TermsAndConditions = React.lazy(() => import('./pages/TermsAndConditions'))
const Admin = React.lazy(() => import('./pages/Admin'))
const EditProject = React.lazy(() => import('./pages/EditProject'))
const Profile = React.lazy(() => import('./pages/Profile'))

import AdminRoute from './components/AdminRoute'
import LoadingScreen from './components/LoadingScreen'
import TermsCookiesModal from './components/TermsCookiesModal'

import { isGlobalAdmin } from './helpers/appRoles'
import { createAppInsightContext } from './api/AppInsights'

const App: FC = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)
  const [authContext, setAuthContext] = useState(false)
  const [silentLogin, setSilentLogin] = useState(true)

  if (isLoading) {
    return <LoadingScreen text='Loading application...' />
  }

  const publicRoutes = [
    <Route key='home_public' path='/login' component={Login} exact={true} />,
    <Route key='terms' path='/termsandconditions' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <TermsAndConditions />
      </Suspense>
    </Route>,
    <Route key='about' path='/about' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <About />
      </Suspense>
    </Route>,
    <Route key='privacy' path='/privacy' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <PrivacyPolicy />
      </Suspense>
    </Route>,
    <Route key='cookies' path='/cookies' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <CookiesPolicy />
      </Suspense>
    </Route>,
  ]

  const privateRoutes = [
    <Route key='home_private' path='/' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        {isGlobalAdmin(user) ? <Admin /> : <Redirect to='/projects' />}
      </Suspense>
    </Route>,
    <Route
      key='project'
      path='/project/:projectId/:phaseId/'
      render={(props) => {
        return <Redirect to={`/project/${props.match.params.phaseId}`} />
      }}
      exact={false}
    ></Route>,
    <Route key='project' path='/project/:phaseId/' component={Project} exact={false} />,
    <Route key='project' path='/project/:slug/' component={Project} exact={false} />,
    <Route key='projects' path='/projects' component={MyProjects} exact={true} />,
    <Route key='noaccess' path='/noaccess/:phaseId?' component={NoAccess} exact={true} />,
    <Route key='userguide' path='/userguide' component={About} exact={true} />,
    <Route key='profile' path='/profile' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <Profile />
      </Suspense>
    </Route>,
    <Route key='project_create' path='/admin/project/create' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <EditProject createMode={true} />
      </Suspense>
    </Route>,
    <Route key='project_create' path='/admin/project/:slugOrPhaseId' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <EditProject createMode={false} />
      </Suspense>
    </Route>,
  ]

  const handleSilentLogin = async () => {
    console.log('** Trying silent login (SSO)')
    try {
      await getAccessTokenSilently()
      console.log('** Logged silently')
    } catch (error) {
      if (error instanceof Error) {
        console.log(`** ${error.message}`)
      }
      setSilentLogin(false)
    }
  }

  if (!isAuthenticated && silentLogin) {
    handleSilentLogin()
    return <LoadingScreen text='Logging user...' />
  }

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter hashType='slash'>
          <Switch>
            {publicRoutes}
            <Route
              render={(props) => {
                return (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location },
                    }}
                  />
                )
              }}
            />
          </Switch>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  if (!authContext) {
    console.log('** Setting AppInsights context...')

    createAppInsightContext()
    setAuthContext(true)
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <ToastProvider>
        <HashRouter hashType='slash'>
          <Switch>
            {privateRoutes}
            {publicRoutes}
            <AdminRoute path='/admin' exact>
              <Suspense fallback={<LoadingScreen text='Loading application...' />}>
                <Admin />
              </Suspense>
            </AdminRoute>
            <Route component={NotFound} />
          </Switch>
          <TermsCookiesModal />
        </HashRouter>
        <Toast />
      </ToastProvider>
    </RecoilRoot>
  )
}

export default App
