import config from '../config/config'
import { User } from '@auth0/auth0-react'
import { IProjectDetails } from '../api/projects'
import { UserRolesEnum } from '../enums/UserRolesEnum'

export const isGlobalAdmin = (user: User | undefined): boolean => {
  const roles = user?.[config.AUTH0_ROLES as keyof typeof user]
  if (!roles) return false

  let globalAdmin = false
  roles.forEach((role: string) => {
    if (role === config.AUTH0_GLOBAL_ADMIN_ROLE) globalAdmin = true
  })

  return globalAdmin
}

export const isProjectAdmin = (details: IProjectDetails | null): boolean =>
  details?.userRole === UserRolesEnum.PROJECT_ADMIN
