import React, { FC, useState } from 'react'
import Style from '../styles/Login.module.sass'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as AlignLogo } from '../assets/align_logo.svg'
import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg'
import { Redirect, useHistory } from 'react-router-dom'
import { SUPPORT_EMAIL, JIRA_SUPPORT_URL } from '../config/config'

interface IState {
  from: { pathname: string }
}

const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const [aboutRedirect, setAboutRedirect] = useState(false)
  const history = useHistory()

  if (isAuthenticated) return <Redirect to='/' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  const handleLogin = () => {
    const state = history.location.state as IState
    const targetUrl = state ? `/#${state.from.pathname}` : '/'

    loginWithRedirect({
      appState: { targetUrl },
    })
  }

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.leftGradient} />
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={30} />
        </div>
        <div className={Style.content}>
          <h2>Welcome to</h2>
          <AlignLogo style={{ height: '40px', width: 'auto' }} />
          <p>
            Align is an interactive tool created to support large rail infrastructure projects by helping visualise
            multiple rail corridor options, making decision-making more agile and user-friendly.
          </p>
          <div className={Style.loginButtons}>
            <Button
              type='primary'
              cssClass='is-white'
              label='Login'
              onClick={handleLogin}
              htmlProps={{ 'aria-label': 'Login', role: 'button' }}
            />
            <Button
              type='secondary'
              cssClass='is-white'
              label='Request Access'
              onClick={() => handleButtonClick(`mailto:${SUPPORT_EMAIL}?subject=Align%20access`)}
              htmlProps={{ 'aria-label': 'Request Access', role: 'button' }}
            />
          </div>
        </div>
        <div className={Style.content} />
        <div className={Style.footer}>
          <Button type='text' cssClass='is-white' label='About' onClick={() => setAboutRedirect(true)} />
          <Button
            type='text'
            cssClass='is-white'
            label='Contact Us'
            onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)}
          />
        </div>
      </div>
      {aboutRedirect && <Redirect to='/about' push />}
    </div>
  )
}

export default Login
