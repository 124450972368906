import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { TokenManager } from './TokenManager'

interface IAppInsightsConfig {
  conn: string
  ip: string
}

export const getAIConn = async (): Promise<IAppInsightsConfig | null> => {
  try {
    const response = await fetch('/app/aiconn')
    const result = await response.json()

    return result || null
  } catch {
    return null
  }
}
const reactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights
let tooManyRequests = false

getAIConn()
  .then((aiConfig) => {
    console.log('** Starting AppInsights...')

    if (!aiConfig) throw Error('missing AppInsights config')

    const browserHistory = createBrowserHistory()
    appInsights = new ApplicationInsights({
      config: {
        connectionString: aiConfig.conn,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    })

    appInsights.loadAppInsights()
    console.log('** AppInsights started...')
  })
  .catch((error: number) => {
    console.log(`** Couldn't start AppInsights. Error: ${error}`)
    tooManyRequests = error === 429
  })

const createAppInsightContext = async () => {
  if (!appInsights) return

  const tokenMgr = await TokenManager()
  const oid = tokenMgr.getUserOID()
  appInsights.setAuthenticatedUserContext(oid)
}

const clearAppInsightContext = async () => {
  if (!appInsights) return

  appInsights.clearAuthenticatedUserContext()
}

export { reactPlugin, appInsights, tooManyRequests, createAppInsightContext, clearAppInsightContext }
