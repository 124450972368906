import { IProjectCategory, categoryMaxColours } from '../api/categories'
import { IProjectData, IProjectFilter } from '../api/projects'
import { MAX_FILE_NAME } from '../config/config'
import { ProjectSortModeEnum } from '../enums/ProjectSortModeEnum'

export type IResponseTypes = object | string | boolean

export interface IResponse {
  success: boolean
  message?: string
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ResponseData = <T extends IResponseTypes>(response: IResponse | null): T | null => {
  if (response && response.success && response.data) return response.data
  return null
}

export type ProjectTabMode = 'view' | 'create' | 'edit'

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isAureconUser = (email: string): boolean => {
  if (!email || !email.includes('@')) return false
  return email.split('@')[1].toUpperCase() === 'AURECONGROUP.COM'
}

export const getUniqueObjects = <T>(array: Array<T>, key: string): T[] => {
  return array.filter((obj, i, self) => self.findIndex((obj2) => obj2[key] === obj[key]) === i)
}

export const getInternalNameFromEmail = (email: string): string => {
  const parts = email.toLowerCase().split('@')
  const nameParts = parts[0].split('.')
  const name = nameParts.map((n) => n.slice(0, 1).toUpperCase() + n.slice(1))
  return name.join(' ')
}

export interface ISyncResult<T> {
  unchanged: Array<T>
  remove: Array<T>
  create: Array<T>
}

export const arraySync = <T>(arrayOne: Array<T>, arrayTwo: Array<T>, key: string): ISyncResult<T> => {
  const result: ISyncResult<T> = {
    unchanged: [],
    remove: [],
    create: [],
  }

  // Items to remove
  arrayOne.forEach((a1) => {
    let remove = true
    arrayTwo.forEach((a2) => {
      if (a1[key] === a2[key]) remove = false
    })

    if (remove) result.remove.push(a1)
    else result.unchanged.push(a1)
  })

  // Items to create
  arrayTwo.forEach((a2) => {
    let create = true
    arrayOne.forEach((a1) => {
      if (a1[key] === a2[key]) create = false
    })

    if (create) result.create.push(a2)
  })

  return result
}

export const findUniqueCategoryColour = (categories: IProjectCategory[]): number => {
  let colour = 1
  for (let i = 1; i < categoryMaxColours + 1; i++) {
    let exists = false
    categories.forEach((cat) => {
      if (cat.colour === i) {
        colour = Math.floor(randomNumber() * 16) + 1
        exists = true
      }
    })
    if (!exists) {
      colour = i
      break
    }
  }

  return colour
}

export interface IDifferenceResult<T> {
  unchanged: Array<T>
  changed: Array<T>
}

export const findDifference = <T>(
  arrayOne: Array<T>,
  arrayTwo: Array<T>,
  uniqueKey: string,
  diffKey: string,
): IDifferenceResult<T> => {
  const result: IDifferenceResult<T> = {
    unchanged: [],
    changed: [],
  }

  for (let i = 0; i < arrayOne.length; i++) {
    const match = arrayTwo.find((element) => arrayOne[i][uniqueKey] === element[uniqueKey])
    if (match) {
      if (arrayOne[i][diffKey] === match[diffKey]) result.unchanged.push(arrayOne[i])
      else result.changed.push(match)
    } else result.unchanged.push(arrayOne[i])
  }

  return result
}

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value))
}

export const unixDateToString = (time: number): string => {
  const dateObject = new Date(Number(time))
  return dateObject.toLocaleString('en-AU', { year: 'numeric', month: 'numeric', day: 'numeric' })
}

export const unixDateTimeToString = (time: number): string => {
  const dateObject = new Date(Number(time))
  return dateObject.toLocaleString('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const headersWithToken = (token: string | undefined): HeadersInit => {
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
}

export const truncateFileName = (name: string): string => {
  const temp = name.split('.')
  const filename = temp.slice(0, -1).join('.').slice(0, MAX_FILE_NAME)
  return `${filename}.${temp[temp.length - 1]}`
}

export const getReadableSize = (size: number | string): string => {
  let fileSizeIndex = 0
  let calculatedSize = Number(size)

  while (calculatedSize > 1024) {
    calculatedSize = calculatedSize / 1024
    fileSizeIndex++
  }

  return `${calculatedSize.toFixed(2)} ${fileSizes[fileSizeIndex]}`
}

const fileSizes = ['B', 'KB', 'MB', 'GB', 'TB']

export const getRandom = (min: number, max: number): number => {
  return Math.floor(randomNumber() * (max - min) + min)
}

export const filterProjects = (projectList: IProjectData[], filter: IProjectFilter | null): IProjectData[] => {
  const textSearch = filter?.textSearch.toLowerCase()
  const sortMode = filter?.sortMode

  if (textSearch)
    projectList = [...projectList].map((project) => {
      if (project.title.toLowerCase().includes(textSearch)) return { ...project }

      const phaseList = project.Phases.filter((phase) => phase.phaseName.toLowerCase().includes(textSearch))
      return { ...project, Phases: phaseList }
    })

  if (sortMode === ProjectSortModeEnum.AlphabetAsc)
    projectList = [...projectList].sort((a, b) => a.title.localeCompare(b.title))

  return projectList
}

export const randomNumber = () => {
  const array = new Uint32Array(1)
  const random = crypto.getRandomValues(array)
  return random[0]
}
