import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createHashHistory } from 'history'

let appInsights: ApplicationInsights
let appInsightsKey: string

const reactPlugin = new ReactPlugin()
const hashHistory = createHashHistory()

const getAIkey = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('GET', '/app/aikey')
    request.send()
    request.onload = () => {
      if (request.status === 200) {
        const response = JSON.parse(request.response)
        resolve(response.key)
      } else {
        reject(false)
      }
    }
  })
}

const getAppInsightsInstance = async (): Promise<ApplicationInsights | null> => {
  if (appInsights) return appInsights

  try {
    if (!appInsightsKey) appInsightsKey = await getAIkey()
  } catch (error) {
    console.log("** Couldn't start AppInsights...")
    return null
  }

  console.log('** Starting AppInsights...')

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: hashHistory },
      },
    },
  })
  appInsights.loadAppInsights()
  appInsights.trackPageView()

  return appInsights
}

export { getAppInsightsInstance, reactPlugin }
