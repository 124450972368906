import React, { FC, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useRecoilValueLoadable, useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as AlignLogo } from '../assets/align_logo.svg'
import { isGlobalAdmin } from '../helpers/appRoles'
import config from '../config/config'

import { AppVersion, ProjectDetails } from '../stores/projectStore'

import { UserRolesEnum } from '../enums/UserRolesEnum'
import { JIRA_SUPPORT_URL } from '../config/config'

import Style from '../styles/TopMenu.module.sass'
import { clearAppInsightContext } from '../api/AppInsights'

export interface IMenuItemProps {
  hash: string
  exact?: boolean
  link: string
  label: string
  small?: boolean
  isExternalLink?: boolean
}

const MenuItem: FC<IMenuItemProps> = (props) => {
  if (props.exact && props.hash === `#${props.link}`) {
    return <div className={props.small ? Style.menuItemSmallActive : Style.menuItemActive}>{props.label}</div>
  }

  if (!props.exact && props.hash.includes(`#${props.link}`)) {
    return <div className={props.small ? Style.menuItemSmallActive : Style.menuItemActive}>{props.label}</div>
  }

  if (props.isExternalLink) {
    return (
      <div className={props.small ? Style.menuItemSmall : Style.menuItem}>
        <Link
          to='/'
          onClick={(e) => {
            e.preventDefault()
            window.open(props.link)
          }}
        >
          {props.label}
        </Link>
      </div>
    )
  }

  return (
    <div className={props.small ? Style.menuItemSmall : Style.menuItem}>
      <Link to={props.link}>{props.label}</Link>
    </div>
  )
}

const TopMenu: FC = () => {
  const { logout, user } = useAuth0()
  const [menuOpen, setMenuOpen] = useState(false)
  const project = useRecoilValue(ProjectDetails)
  const version = useRecoilValueLoadable(AppVersion)
  const globalAdmin = isGlobalAdmin(user)
  const hash = window.location.hash

  if (!user) return null

  const projectPage = () => {
    if (!project) return null

    const title = project.title.length > 18 ? `${project.title.substring(0, 18)}...` : project.title
    const phase = project.phaseName.length > 18 ? `${project.phaseName.substring(0, 18)}...` : project.phaseName

    if (hash.includes('#/project/'))
      return (
        <div className={Style.menuItemActive}>
          {title} / <br />
          {phase}
        </div>
      )

    return (
      <div className={Style.menuItem}>
        <Link to={`/project/${project.slug || project.phaseId}`}>
          {title} / <br />
          {phase}
        </Link>
      </div>
    )
  }

  const projectSettings = () => {
    if (!project) return null

    if (
      project.userRole &&
      project.userRole !== UserRolesEnum.PROJECT_ADMIN &&
      project.userRole !== UserRolesEnum.DATA_MANAGER &&
      !globalAdmin
    )
      return null

    if (hash.includes('#/admin/project/')) return <div className={Style.menuItemActive}>Project Settings</div>

    return (
      <div className={Style.menuItem}>
        <Link to={`/admin/project/${project.slug || project.phaseId}`}>Project Settings</Link>
      </div>
    )
  }

  const handleLogout = () => {
    clearAppInsightContext()
    logout({ logoutParams: { returnTo: config.AUTH0_LOGOUT_RETURN } })
  }

  const getCurrentYear = () => new Date().getFullYear()

  return (
    <div className={Style.topMenuWrapper} onClick={() => setMenuOpen(!menuOpen)}>
      <div className={Style.topMenu}>
        <AlignLogo height={24} />
        <Icon type={menuOpen ? 'expand_less' : 'expand_more'} className={Style.menuIcon} />
      </div>
      {menuOpen && (
        <>
          {projectPage()}
          {projectSettings()}
          {project && <div className={Style.menuDivider} />}
          <MenuItem exact hash={hash} link='/projects' label='My Projects' />
          {globalAdmin && <MenuItem exact hash={hash} link='/' label='Global Admin Panel' />}
          <MenuItem exact hash={hash} link='/profile' label='My Account Details' />
          <div className={Style.menuItem}>
            <button onClick={handleLogout}>Logout</button>
          </div>
          <div className={Style.menuDivider} />
          <MenuItem exact hash={hash} link='/about' label='About Align' />
          <MenuItem exact hash={hash} link={JIRA_SUPPORT_URL} label='Contact Us' isExternalLink />
          <div className={Style.menuSmall}>
            <MenuItem exact small hash={hash} link='/privacy' label='Privacy Policy' />
            <MenuItem exact small hash={hash} link='/cookies' label='Cookies Policy' />
            <MenuItem exact small hash={hash} link='/termsandconditions' label='Terms & Conditions' />
          </div>
          <div className={Style.menuFooter}>
            ©{getCurrentYear()} Aurecon Group Pty Ltd
            <br />
            align version {version.state === 'hasValue' && version.contents}
          </div>
        </>
      )}
    </div>
  )
}

export default TopMenu
