import React, { FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Modal, Grid, Button } from '@aurecon-creative-technologies/styleguide'
import Cookies from 'universal-cookie'
import { useAuth0 } from '@auth0/auth0-react'
import { add } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { notifyServiceDesk } from '../api/sendgrid'
import { TermsModal } from '../stores/uiStore'
import config from '../config/config'

import Style from '../styles/TermsCookiesModal.module.sass'
import { clearAppInsightContext } from '../api/AppInsights'

const cookies = new Cookies()

const TermsCookiesModal: FC = () => {
  const { logout, user } = useAuth0()
  const { pathname } = useLocation()
  const [acceptedCookies, setAcceptedCookies] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [termsModal, setTermsModal] = useRecoilState(TermsModal)

  useEffect(() => {
    const c = cookies.get('aal.cookies')
    const t = cookies.get('aal.terms')

    if (c && c.accepted) setAcceptedCookies(true)
    if (t && t.accepted) setAcceptedTerms(true)
  }, [])

  useEffect(() => {
    setTermsModal(!acceptedTerms || !acceptedCookies)
  }, [acceptedCookies, acceptedTerms, setTermsModal])

  const handleAcceptTerms = () => {
    cookies.set('aal.terms', { accepted: true }, { path: '/', expires: add(new Date(), { years: 1 }) })
    setAcceptedTerms(true)
  }

  const handleAcceptCookies = () => {
    cookies.set('aal.cookies', { accepted: true }, { path: '/', expires: add(new Date(), { years: 1 }) })
    setAcceptedCookies(true)
  }

  const handleDeclineTerms = () => {
    cookies.set('aal.terms', { accepted: false }, { path: '/', expires: add(new Date(), { years: 1 }) })
    if (user?.name && user?.email) notifyServiceDesk({ request: { name: user.name, email: user.email } })
    setAcceptedTerms(false)

    clearAppInsightContext()
    logout({ logoutParams: { returnTo: config.AUTH0_LOGOUT_RETURN } })
  }

  const handleCloseModal = () => {
    // required callback for modal
  }

  const noOverlayPages =
    pathname.includes('termsandconditions') || pathname.includes('cookie') || pathname.includes('privacy')

  return (
    <Modal
      isShowing={termsModal}
      onClose={handleCloseModal}
      isCloseButton={false}
      size='medium'
      cssClass={`is-cookie-modal ${noOverlayPages ? 'no-overlay' : ''}`}
      isOverlay={!noOverlayPages}
    >
      {!acceptedTerms && (
        <Grid row gap={12}>
          <Grid item cell xs={8}>
            <p>
              Please consent to the <a href='/#/termsandconditions'>Terms & Conditions</a> and{' '}
              <a href='/#/privacy'>Privacy Policy</a>.
            </p>
          </Grid>
          <Grid item cell>
            <Button label='Decline' type='secondary' onClick={handleDeclineTerms} />
          </Grid>
          <Grid item cell>
            <Button label='Accept' onClick={handleAcceptTerms} />
          </Grid>
        </Grid>
      )}
      {!acceptedCookies && !acceptedTerms && <div className={Style.modalDivider} />}
      {!acceptedCookies && (
        <Grid row gap={12}>
          <Grid item cell xs={10}>
            <p>
              We use cookies to ensure that we give you the best experience on our application.{' '}
              <a href='/#/cookies'>Find out more</a>
            </p>
          </Grid>
          <Grid item cell>
            <Button label='Accept' onClick={handleAcceptCookies} />
          </Grid>
        </Grid>
      )}
    </Modal>
  )
}

export default TermsCookiesModal
